<script setup lang="ts">
import AbortIcon from '@assets/icons/abort.svg';
import HmrLogo from '@assets/icons/hmr-logo.svg';
import LogoIcon from '@assets/icons/jrnys-logo.svg';
import ProfileIcon from '@assets/icons/profile.svg';
import RestartIcon from '@assets/icons/restart.svg';
import BaseButton from '@components/base/BaseButton.vue';
import { NavigationMenu } from '@components/navigation-menu';
import { QuizLoader, QuizStep } from '@components/quiz';
import { SITE_HANDLE } from '@models/menu';
import { MODAL_TYPE } from '@models/modals';
import { QUIZ_TYPE, STEP_TYPE, StepAnswer } from '@models/quiz';
import { useQuizStore, useUserStore } from '@stores/index';
import { ref } from 'vue';

type Props = {
  handle: QUIZ_TYPE;
}

const props = defineProps<Props>();

const userStore = useUserStore();
const quizStore = useQuizStore();
const loading = ref(false);

userStore.getInfo().then(() => {
  quizStore.getQuiz(props.handle);
});

const next = (payload?: StepAnswer<any>[], stepId?: number) => {
  if (payload) {
    loading.value = true;
    quizStore.setAnswer(payload, stepId)
      .finally(() => {
        loading.value = false;
      });
  }

  quizStore.next();
};
const siteHandle = window.siteHandle;
</script>

<template>
  <div class="quiz">
    <header class="pb-36 pt-30 lg:pt-56">
      <div class="container flex items-center justify-between gap-16">
        <a
          v-if="siteHandle === SITE_HANDLE.HMRPROGRAM"
          href="https://www.hmrprogram.com/rx-plan"
          target="_blank"
        >
          <HmrLogo class="h-[38px] w-[209px] lg:h-[51px] lg:w-[277px]" />
        </a>
        <a
          v-else
          href="/"
        >
          <LogoIcon class="h-[27px] w-[72px] text-deep-blue lg:h-[31px] lg:w-[82px]" />
        </a>

        <NavigationMenu
          v-if="!userStore.info?.isGuest"
          :items="$root.userMenuItems"
        >
          <ProfileIcon class="h-[32px] w-[32px]" />
        </NavigationMenu>

        <button
          v-else
          class="text-turquoise hover:text-deep-blue"
          type="button"
          @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
        >
          <ProfileIcon class="h-[32px] w-[32px]" />
        </button>
      </div>
    </header>

    <Transition
      name="fade"
      mode="out-in"
    >
      <QuizLoader v-if="quizStore.loading">
        Hang tight while we prepare some questions.
      </QuizLoader>

      <div
        v-else
        class="flex grow flex-col pt-8 sm:pt-0"
      >
        <Transition
          name="quiz-slide"
          mode="out-in"
        >
          <div
            v-if="quizStore.currentStep"
            :key="quizStore.currentStep.id"
            class="sm:my-auto"
          >
            <QuizStep
              :step="quizStore.currentStep"
              :answers="quizStore.answers[quizStore.currentStep.id]"
              :position="quizStore.counter"
              @next="next"
              @back="quizStore.back"
            />
          </div>
        </Transition>
      </div>
    </Transition>

    <footer class="mt-auto pb-16 pt-30 sm:pb-30 sm:pt-90">
      <div class="container">
        <div class="grid items-center gap-8 sm:gap-16 md:grid-cols-3">
          <div class="sm:text-center md:text-left">
            <div class="flex items-center gap-30">
              <Transition name="fade">
                <BaseButton
                  v-if="!quizStore.paid && !quizStore.loading"
                  class="text-warm-grey hover:bg-turquoise hover:text-white"
                  data-testid="quiz-restart-button"
                  text="Restart Quiz"
                  size="sm"
                  @click="quizStore.restart"
                >
                  <template #icon-left>
                    <RestartIcon class="h-[16px]" />
                  </template>
                </BaseButton>
              </Transition>
              <Transition name="fade">
                <BaseButton
                  v-if="!quizStore.paid && !quizStore.loading"
                  class="text-warm-grey hover:bg-turquoise hover:text-white"
                  data-testid="quiz-abort-button"
                  text="Delete Intake"
                  size="sm"
                  @click="quizStore.abort"
                >
                  <template #icon-left>
                    <AbortIcon class="h-[16px]" />
                  </template>
                </BaseButton>
              </Transition>
            </div>
          </div>

          <Transition name="fade">
            <div
              v-if="(userStore.info?.isGuest && quizStore.currentStep?.type !== STEP_TYPE.AUTH)
                || quizStore.loading"
              class="sm:text-center"
            >
              Already a member?
              <button
                class="font-bold hover:text-turquoise"
                type="button"
                data-testid="quiz-login"
                @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
              >
                Sign in
              </button>
            </div>
          </Transition>
        </div>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped></style>
