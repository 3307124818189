import { apiCart } from '@api/cart';
import { CardComponent } from '@chargebee/chargebee-js-vue-wrapper';
import { useFetch } from '@helpers/use-fetch';
import { CartItemPayload } from '@models/cart';
import { PRODUCT_TYPE, ProductUsage, type CartProduct } from '@models/product';
import { QUIZ_TYPE } from '@models/quiz';
import { VARIANT_TYPE } from '@models/variant';
import { useQuizStore } from '@stores/quiz';
import { useVuelidate } from '@vuelidate/core';
import { xor, omit, sumBy, intersectionBy } from 'lodash';
import { computed, ref, watch } from 'vue';
import type { CartItem, CartForm, VariantForm } from '../types';
import { useProfile } from './useProfile';
import { useValidators } from './useValidators';

export const useCart = (products: CartProduct[] = []) => {
  const quizStore = useQuizStore();
  const { profileUser } = useProfile();
  const isShippingAddressSame = ref(true);

  const {
    items,
    period,
    dosage,
    quantity,
    billingAddress,
    shippingAddress,
    agreement,
  } = useValidators({
    isShippingAddressSame,
  });

  const cardComponent = ref<InstanceType<typeof CardComponent> | null>(null);
  const cardSection = ref<HTMLElement | null>(null);

  const productTypeOptions = [
    { label: 'Before sex options', value: 'beforeSex' },
    { label: 'Daily options', value: 'daily' },
  ] satisfies { label: string; value: ProductUsage }[];

  const selectedProductTypeOption = ref(productTypeOptions[0]);

  const shouldFilterProductsByType = computed(() => {
    return [
      QUIZ_TYPE.QUIZ_ERECTILE_DYSFUNCTION,
      QUIZ_TYPE.QUIZ_EARLY_CLIMAX,
    ].includes(quizStore.quizType!);
  });

  const mainProducts = computed(() => {
    return products.filter((p) => (
      !p.product.productType.includes(PRODUCT_TYPE.ADD_ON)
        && !p.product.productType.includes(PRODUCT_TYPE.DIGITAL)
    ))
      // Daily/Before sex filter for ED/EC
      .filter((p) => (shouldFilterProductsByType.value
        ? p.product.productUsage.includes(selectedProductTypeOption.value.value)
        : true
      ));
  });

  const addons = computed(() => {
    return products.filter((p) => p.product.productType.includes(PRODUCT_TYPE.ADD_ON));
  });

  const membership = computed(() => products.find((p) => p.product.productType.includes(PRODUCT_TYPE.DIGITAL)));

  const selectedMainProductId = ref<number | null>(null);
  const selectedAddonIdList = ref<number[]>([]);

  const selectMainProduct = (productId: number) => {
    selectedMainProductId.value = selectedMainProductId.value !== productId ? productId : null;
  };

  const selectAddon = (productId: number) => {
    selectedAddonIdList.value = xor(selectedAddonIdList.value, [productId]);
  };

  const selectedMainProduct = computed(() => {
    return products.find((p) => p.product.id === selectedMainProductId.value);
  });

  // Sorted list of available periods for the selected main product
  const periodOptions = computed(() => {
    const subscriptionVariants = selectedMainProduct.value?.product.variants
      .filter((variant) => variant.variantType === VARIANT_TYPE.SUBSCRIPTION && variant.variantPeriod) || [];

    const membershipVariants = membership.value?.product.variants
      .filter((variant) => variant.variantType === VARIANT_TYPE.SUBSCRIPTION_ADDON && variant.variantPeriod) || [];

    const intersectedVariants = intersectionBy(subscriptionVariants, membershipVariants, 'variantPeriod');

    return intersectedVariants
      .sort((a, b) => b.variantPeriod! - a.variantPeriod!)
      .map((variant) => {
        const label = variant.variantPeriod === 1
          ? 'Shipped monthly'
          : `${variant.variantPeriod} month supply`;

        const membership = membershipVariants.find((membershipVariant) => {
          return membershipVariant.variantPeriod === variant.variantPeriod;
        })!;

        return {
          label,
          period: variant.variantPeriod!,
          amount: variant.variantAmount / variant.variantPeriod!,
          membershipAmount: membership.variantAmount / variant.variantPeriod!,
        };
      });
  });

  const variantForm = ref<VariantForm>({
    period: null,
    dosage: null,
    quantity: selectedProductTypeOption.value.value === 'daily' ? 30 : null,
  });

  const variant$ = useVuelidate({ period, dosage, quantity }, variantForm);

  const cartForm = ref<CartForm>({
    items: [],
    coupon: '',
    billingAddress: {
      firstName: '',
      lastName: '',
      phone: '',
      line1: '',
      line2: '',
      city: '',
      zip: '',
      state_code: '',
    },
    shippingAddress: null,
    tokenId: '',
    submissionId: quizStore.submissionId!,
    eventId: crypto.randomUUID(),
    source: 'quiz',
  });

  const cart$ = useVuelidate({ items }, cartForm);

  const billingAddress$ = useVuelidate({ billingAddress }, cartForm);
  const shippingAddress$ = useVuelidate({ shippingAddress }, cartForm);

  const checkboxesForm = ref({
    agreement: false,
  });

  const checkboxes$ = useVuelidate({ agreement }, checkboxesForm);

  // All selected products (main product and addons) + plans
  const selectedProducts = computed(() => {
    return products.filter((p) => (
      p.product.id === selectedMainProductId.value
        || selectedAddonIdList.value.includes(p.product.id)
        || p.product.productType.includes(PRODUCT_TYPE.DIGITAL)
    ));
  });

  // Cart items with all necessary fields
  const cartItems = computed<CartItem[]>(() => {
    return selectedProducts.value.map((p) => {
      // Cart item for membership
      if (p.product.productType.includes(PRODUCT_TYPE.DIGITAL)) {
        const variant = p.product.variants.find((variant) => {
          return variant.variantType === VARIANT_TYPE.SUBSCRIPTION_ADDON
            && variant.variantPeriod === variantForm.value.period;
        });

        return {
          type: 'membership' as CartItem['type'],
          productId: p.product.id,
          // productType: p.product.productType,
          variantId: variant?.id || null,
          variantAmount: variant?.variantAmount || 0,
          quantity: 1,
          shippingPrice: p.product.shippingPrices[0]?.shippingAmount || 0,
        };
      }

      // Cart item for addon
      if (p.product.productType.includes(PRODUCT_TYPE.ADD_ON)) {
        const variant = p.product.variants.find((variant) => {
          return variant.variantType === VARIANT_TYPE.SUBSCRIPTION_ADDON;
        });

        return {
          type: 'addon' as CartItem['type'],
          productId: p.product.id,
          // productType: p.product.productType,
          variantId: variant?.id || null,
          variantAmount: variant?.variantAmount || 0,
          quantity: 1,
          shippingPrice: p.product.shippingPrices[0]?.shippingAmount || 0,
          dosage: variantForm.value.dosage,
          dosageUnit: p.product.dosageUnit,
        };
      }

      // Cart item for main product
      const variant = p.product.variants.find((variant) => {
        return variant.variantType === VARIANT_TYPE.SUBSCRIPTION
          && variant.variantPeriod === variantForm.value.period;
      });

      return {
        type: 'medication' as CartItem['type'],
        productId: p.product.id,
        // productType: p.product.productType,
        variantId: variant?.id || null,
        variantAmount: variant?.variantAmount || 0,
        quantity: variantForm.value.quantity || 1,
        shippingPrice: p.product.shippingPrices[0]?.shippingAmount || 0,
        dosage: variantForm.value.dosage,
        dosageUnit: p.product.dosageUnit,
      };
    }).filter((item) => item.variantId);
  });

  // Payload cart items
  const cartPayloadItems = computed<CartItemPayload[]>(() => {
    return cartItems.value.map((item) => omit(item, ['type', 'variantAmount', 'shippingPrice']));
  });

  watch(cartPayloadItems, (items) => {
    cartForm.value.items = items;
  });

  watch(selectedProductTypeOption, (type) => {
    cartForm.value.items = [];

    variantForm.value = {
      period: null,
      dosage: null,
      quantity: type.value === 'daily' ? 30 : null,
    };
  });

  // const getMinPriceByUses = (quantity: number) => {
  //   const minPrice = selectedMainProduct.value?.min_price || 0;
  //   const totalPrice = minPrice * quantity;
  //   return `From ${formatUnitAmountCents(totalPrice)}/month`;
  // };

  const membershipPrice = computed(() => {
    return cartItems.value.find((item) => item.type === 'membership')
      ?.variantAmount || 0;
  });

  const addonPrice = computed(() => {
    return cartItems.value.find((item) => item.type === 'addon')
      ?.variantAmount || 0;
  });

  const medicationPrice = computed(() => {
    return cartItems.value.find((item) => item.type === 'medication')
      ?.variantAmount || 0;
  });

  const shippingPrice = computed(() => {
    return cartItems.value.reduce((result, item) => {
      return result + item.shippingPrice;
    }, 0);
  });

  const totalPrice = computed(() => {
    return cartItems.value.reduce((result, item) => {
      return item.variantAmount * item.quantity + result;
    }, shippingPrice.value);
  });

  // Buy now
  const buyNowApi = useFetch(apiCart.buyNow);
  const buyNowLoading = ref(false);

  const buyNow = async () => {
    const errorSection = document.querySelector('.validation-error');

    if (
      !await cart$.value.$validate()
      || !await variant$.value.$validate()
    ) {
      errorSection?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    buyNowLoading.value = true;

    try {
      const data = await cardComponent.value?.tokenize();
      cartForm.value.tokenId = data.token;
    } catch {
      cardSection.value?.scrollIntoView({ behavior: 'smooth' });
      buyNowLoading.value = false;
      return;
    }

    if (
      !await billingAddress$.value.$validate()
      || !await shippingAddress$.value.$validate()
      || !await checkboxes$.value.$validate()
    ) {
      errorSection?.scrollIntoView({ behavior: 'smooth' });
      buyNowLoading.value = false;
      return;
    }

    const response = await buyNowApi.execute(cartForm.value);

    buyNowLoading.value = false;

    if (!response?.data.url) return;
    location.href = response.data.url;
  };

  // Coupon
  const couponInput = ref('');
  const estimateApi = useFetch(apiCart.estimate);
  const estimateData = computed(() => estimateApi.response.value?.data);
  const estimateLoading = computed(() => estimateApi.loading.value);
  const estimateError = computed(() => estimateApi.error.value);

  const applyCoupon = async () => {
    if (
      !await cart$.value.$validate()
      || !await variant$.value.$validate()
    ) {
      const errorSection = document.querySelector('.validation-error');
      errorSection?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (!couponInput.value) return;
    if (estimateLoading.value) return;

    await estimateApi.execute({
      ...omit(cartForm.value, ['tokenId']),
      coupon: couponInput.value,
    });

    if (estimateError.value) {
      return;
    }

    cartForm.value.coupon = couponInput.value;
    couponInput.value = '';
  };

  const discountPrice = computed(() => {
    const discount = sumBy(estimateData.value?.estimation.next_invoice_estimate?.discounts, 'amount');
    return totalPrice.value - discount;
  });

  const removeCoupon = () => {
    cartForm.value.coupon = '';
    couponInput.value = '';
  };

  watch(() => cartForm.value.items, () => {
    removeCoupon();
  });

  // Prefill address
  watch(profileUser, (user) => {
    cartForm.value.billingAddress.firstName = user?.firstName ?? '';
    cartForm.value.billingAddress.lastName = user?.lastName ?? '';
    cartForm.value.billingAddress.phone = user?.addresses.billingAddress?.phone ?? '';
    cartForm.value.billingAddress.line1 = user?.addresses.billingAddress?.addressLine1 ?? '';
    cartForm.value.billingAddress.line2 = user?.addresses.billingAddress?.addressLine2 ?? '';
    cartForm.value.billingAddress.city = user?.addresses.billingAddress?.locality ?? '';
    cartForm.value.billingAddress.zip = user?.addresses.billingAddress?.postalCode ?? '';
    cartForm.value.billingAddress.state_code = user?.addresses.billingAddress?.administrativeArea ?? '';
  });

  watch(isShippingAddressSame, (isSame) => {
    cartForm.value.shippingAddress = !isSame ? {
      firstName: '',
      lastName: '',
      phone: '',
      line1: '',
      line2: '',
      city: '',
      zip: '',
      state_code: '',
    } : null;

    if (!cartForm.value.shippingAddress) {
      return;
    }

    cartForm.value.shippingAddress.firstName = profileUser.value?.firstName ?? '';
    cartForm.value.shippingAddress.lastName = profileUser.value?.lastName ?? '';
    cartForm.value.shippingAddress.phone = profileUser.value?.addresses.shippingAddress?.phone ?? '';
    cartForm.value.shippingAddress.line1 = profileUser.value?.addresses.shippingAddress?.addressLine1 ?? '';
    cartForm.value.shippingAddress.line2 = profileUser.value?.addresses.shippingAddress?.addressLine2 ?? '';
    cartForm.value.shippingAddress.city = profileUser.value?.addresses.shippingAddress?.locality ?? '';
    cartForm.value.shippingAddress.zip = profileUser.value?.addresses.shippingAddress?.postalCode ?? '';
    cartForm.value.shippingAddress.state_code = profileUser.value?.addresses.shippingAddress?.administrativeArea ?? '';
  });

  return {
    mainProducts,
    addons,
    productTypeOptions,
    selectedProductTypeOption,
    selectedMainProductId,
    selectedAddonIdList,
    selectMainProduct,
    selectAddon,
    selectedMainProduct,
    buyNow,
    buyNowLoading,
    periodOptions,
    cartForm,
    variantForm,
    checkboxesForm,
    shouldFilterProductsByType,
    membershipPrice,
    addonPrice,
    medicationPrice,
    shippingPrice,
    totalPrice,
    isShippingAddressSame,
    cardComponent,
    cart$,
    variant$,
    billingAddress$,
    shippingAddress$,
    checkboxes$,
    cardSection,
    applyCoupon,
    discountPrice,
    estimateLoading,
    couponInput,
    removeCoupon,
  };
};
