<script setup lang="ts">
import { BaseSelect } from '@components/base';
import type { CartAddress } from '@models/cart';
import type { BaseValidation, NestedValidations } from '@vuelidate/core';
import { vMaska } from 'maska';
import { computed, reactive, watch } from 'vue';

const props = defineProps<{
  address: CartAddress;
  validation: BaseValidation<CartAddress | null> & NestedValidations<CartAddress>;
  states: { label: string, value: string }[]
}>();

const emit = defineEmits<{
  'update': [value: CartAddress]
}>();

const address = reactive(props.address);

watch(address, (address) => {
  emit('update', {
    ...address,
    phone: address.phone.replaceAll(/\s/g, ''),
  });
});

const stateModel = computed({
  get: () => props.states.find((option) => option.value === address.state_code),
  set: (option?: { label: string, value: string }) => address.state_code = option!.value,
});
</script>

<template>
  <form class="flex flex-col gap-20">
    <div class="flex gap-10">
      <div class="grow">
        <h3
          class="px-16 text-11 font-bold uppercase text-turquoise"
          :class="{ '!text-red': validation.firstName.$error }"
        >
          First Name
        </h3>
        <input
          v-model="address.firstName"
          class="input mt-10 w-full"
          :class="{ 'input--error': validation.firstName.$error }"
          type="text"
          placeholder="Prepopulated"
          data-testpl="quiz-cart-address-first-name"
        >
        <p
          v-if="validation.firstName.$error"
          class="relative mt-10 text-red"
        >
          {{ validation.firstName.$errors[0]?.$message }}
        </p>
      </div>
      <div class="grow">
        <h3
          class="px-16 text-11 font-bold uppercase text-turquoise"
          :class="{ '!text-red': validation.lastName.$error }"
        >
          Last Name
        </h3>
        <input
          v-model="address.lastName"
          class="input mt-10 w-full"
          :class="{ 'input--error': validation.lastName.$error }"
          type="text"
          placeholder="Prepopulated"
          data-testpl="quiz-cart-address-last-name"
        >
        <p
          v-if="validation.lastName.$error"
          class="relative mt-10 text-red"
        >
          {{ validation.lastName.$errors[0]?.$message }}
        </p>
      </div>
    </div>

    <div class="w-full">
      <h3
        class="px-16 text-11 font-bold uppercase text-turquoise"
        :class="{ '!text-red': validation.phone.$error }"
      >
        Phone
      </h3>
      <input
        v-model="address.phone"
        v-maska
        class="input mt-10 w-full"
        :class="{ 'input--error': validation.phone.$error }"
        type="text"
        data-testpl="quiz-cart-address-phone"
        data-maska="+# ### ### ####"
      >
      <p
        v-if="validation.phone.$error"
        class="relative mt-10 text-red"
      >
        {{ validation.phone.$errors[0]?.$message }}
      </p>
    </div>
    <div class="w-full">
      <h3
        class="px-16 text-11 font-bold uppercase text-turquoise"
        :class="{ '!text-red': validation.line1.$error }"
      >
        Address Line 1
      </h3>
      <input
        v-model="address.line1"
        class="input mt-10 w-full"
        :class="{ 'input--error': validation.line1.$error }"
        type="text"
        data-testpl="quiz-cart-address-line1"
      >
      <p
        v-if="validation.line1.$error"
        class="relative mt-10 text-red"
      >
        {{ validation.line1.$errors[0]?.$message }}
      </p>
    </div>
    <div class="w-full">
      <h3 class="px-16 text-11 font-bold uppercase text-turquoise">
        Address Line 2 <span class="text-deep-blue-50">(Optional)</span>
      </h3>
      <input
        v-model="address.line2"
        class="input mt-10 w-full"
        type="text"
        data-testpl="quiz-cart-address-line2"
      >
    </div>
    <div class="grid grid-cols-[2fr,1fr,2fr] gap-10">
      <div>
        <h3
          class="px-16 text-11 font-bold uppercase text-turquoise"
          :class="{ '!text-red': validation.city.$error }"
        >
          City
        </h3>
        <input
          v-model="address.city"
          class="input mt-10 w-full"
          :class="{ 'input--error': validation.city.$error }"
          type="text"
          data-testpl="quiz-cart-address-city"
        >
      </div>
      <div>
        <h3
          class="px-16 text-11 font-bold uppercase text-turquoise"
          :class="{ '!text-red': validation.zip.$error }"
        >
          ZIP
        </h3>
        <input
          v-model="address.zip"
          v-maska
          class="input mt-10 w-full"
          :class="{ 'input--error': validation.zip.$error }"
          type="text"
          data-testpl="quiz-cart-address-zip"
          data-maska="#####"
        >
      </div>
      <div>
        <h3 class="px-16 text-11 font-bold uppercase text-turquoise">
          State
        </h3>
        <BaseSelect
          v-model="stateModel"
          :options="states"
          label="label"
          track-by="value"
          class="mt-10 w-full"
          placeholder=""
          data-testpl="quiz-cart-address-state"
        />
      </div>
      <div class="relative col-span-3 mt-10 text-red">
        <p v-if="validation.city.$error">
          {{ validation.city.$errors[0]?.$message }}
        </p>
        <p v-if="validation.zip.$error">
          {{ validation.zip.$errors[0]?.$message }}
        </p>
        <p v-if="validation.state_code.$error">
          {{ validation.state_code.$errors[0]?.$message }}
        </p>
      </div>
    </div>
  </form>
</template>
