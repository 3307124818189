<script setup lang="ts">
import IconMinus from '@assets/icons/minus.svg';
import IconPlus from '@assets/icons/plus.svg';
import IconWarning from '@assets/icons/warning.svg?component';
import { BaseButton } from '@components/base';
import { type CartProduct } from '@models/product';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  selected?: boolean;
  item: CartProduct;
}>(), {
  selected: false,
});

const emit = defineEmits<{
  'select': []
}>();

const isRecommended = computed(() => !!props.item.product.label?.toLowerCase().includes('recommend'));
</script>

<template>
  <div
    class="product-card relative flex flex-col justify-between gap-10 rounded-[20px] bg-white p-10 outline outline-4 -outline-offset-4 outline-transparent transition-[outline-color] duration-300 md:p-20"
    :class="{ '!outline-turquoise': selected }"
  >
    <div
      v-if="item.product.label"
      class="absolute inset-x-0 bottom-full flex justify-center px-20"
    >
      <div
        class="flex h-34 max-w-full items-center rounded-t-[20px] px-20 text-11 font-bold uppercase"
        :class="{
          'bg-turquoise text-white': isRecommended,
          'bg-[#CCCCCC] text-[#7E7E7E]': !isRecommended,
        }"
      >
        <span class="truncate">{{ item.product.label }}</span>
      </div>
    </div>

    <header>
      <p class="text-right text-18 text-turquoise">
        {{ item.price }}
      </p>
      <h1 class="mt-10 text-24 md:mt-8">
        {{ item.product?.title }}
      </h1>

      <div class="text-14 md:mt-10 md:text-16">
        <p class="font-semibold text-turquoise">
          {{ item.clarification }}
        </p>
        <!-- <p class="text-deep-blue-50">
          Same active ingredient as Ozempic, Wegovvy
        </p> -->
      </div>
    </header>

    <figure
      v-if="item.product?.image"
      class="h-[200px] md:h-[216px]"
    >
      <img
        class="h-full w-full object-contain"
        :src="item.product.image.url ?? ''"
        alt=""
      >
    </figure>

    <div class="flex flex-col gap-10">
      <BaseButton
        class="min-w-[140px] bg-deep-blue-30 text-white hover:bg-deep-blue hover:text-white"
        text="More info"
        size="sm"
        :href="item.url"
        target="_blank"
        data-testpl="quiz-cart-product-more-btn"
      >
        <template #icon-right>
          <IconWarning />
        </template>
      </BaseButton>

      <BaseButton
        class="w-full bg-turquoise text-white hover:bg-deep-blue hover:text-white"
        :text="selected ? 'Remove' : 'Select'"
        data-testpl="quiz-cart-product-select-btn"
        @click="emit('select')"
      >
        <template #icon-right>
          <component :is="selected ? IconMinus : IconPlus" />
        </template>
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-card {
  box-shadow: $soft-shadow;
}
</style>
