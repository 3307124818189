<script setup lang="ts">
import IconCheck from '@assets/icons/check.svg';
import ChevronLeft from '@assets/icons/chevron-left.svg';
import ChevronRight from '@assets/icons/chevron-right.svg';
import IconCreditCard from '@assets/icons/credit-card.svg';
import IconMinus from '@assets/icons/minus.svg';
import IconPlus from '@assets/icons/plus.svg';
import IconSpinner from '@assets/icons/spinner.svg';
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCvv,
} from '@chargebee/chargebee-js-vue-wrapper';
import ProductCard from '@components/ProductCard/ProductCard.vue';
import { BaseCheck, BaseSwitch, BaseButton, BaseSelect } from '@components/base';
import { formatUnitAmountCents } from '@helpers/tools';
import { QUIZ_TYPE, StepContentBody } from '@models/quiz';
import { useQuizStore } from '@stores/quiz';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { register } from 'swiper/element/bundle';
import { computed } from 'vue';
import AddressForm from './components/AddressForm.vue';
import { useCart } from './composables/useCart';
import { useChargebee } from './composables/useChargebee';

const props = defineProps<{
  content: StepContentBody;
}>();

const emit = defineEmits<{
  next: [];
  back: [];
}>();

register();

const breakpoints = useBreakpoints(breakpointsTailwind);
const greaterOrEqualMd = breakpoints.greaterOrEqual('md');
const quizStore = useQuizStore();

const {
  cardSettings,
  onCardChange,
  cardErrors,
  cardErrorMessage,
} = useChargebee();

const {
  mainProducts,
  addons,
  productTypeOptions,
  selectedProductTypeOption,
  selectedMainProductId,
  selectedAddonIdList,
  selectMainProduct,
  selectAddon,
  selectedMainProduct,
  buyNow,
  buyNowLoading,
  periodOptions,
  cartForm,
  variantForm,
  checkboxesForm,
  shouldFilterProductsByType,
  membershipPrice,
  addonPrice,
  medicationPrice,
  totalPrice,
  isShippingAddressSame,
  cardComponent,
  cart$,
  variant$,
  billingAddress$,
  shippingAddress$,
  checkboxes$,
  cardSection,
  applyCoupon,
  estimateLoading,
  discountPrice,
  couponInput,
  removeCoupon,
} = useCart(props.content.products);

const isUsesChecklist = computed(() => (
  [
    QUIZ_TYPE.QUIZ_ERECTILE_DYSFUNCTION,
    QUIZ_TYPE.QUIZ_EARLY_CLIMAX,
  ].includes(quizStore.quizType!)
    && selectedProductTypeOption.value.value === 'beforeSex'
    && selectedMainProduct.value
));

const isDosageChecklist = computed(() => (
  [
    QUIZ_TYPE.QUIZ_ERECTILE_DYSFUNCTION,
    QUIZ_TYPE.QUIZ_EARLY_CLIMAX,
  ].includes(quizStore.quizType!)
    && !!selectedMainProduct.value?.product.dosages.length
));

window.Chargebee?.init({
  site: 'jrnys-test',
  publishableKey: 'test_IX5diUEK7DB69LWeqxuIKbcuFT9INNfncu',
});
</script>

<template>
  <div class="overflow-hidden px-20 md:px-40">
    <!-- <pre>
      {{ cartForm }}
    </pre> -->
    <header class="col">
      <h1 class="text-37 font-light leading-110 md:text-50">
        {{ content.header }}
      </h1>

      <p
        v-if="!greaterOrEqualMd"
        class="mt-40 text-14"
      >
        <!-- Based on your goal to <span class="font-bold">lose 51lbs</span>, along the information you provided, you may be eligible for our recommended treatment: -->
        Based on your goal, along the information you provided, you may be eligible for our recommended treatment:
      </p>
    </header>

    <section
      v-if="shouldFilterProductsByType"
      class="col pt-60"
    >
      <BaseSelect
        v-model="selectedProductTypeOption"
        :options="productTypeOptions"
        label="label"
        track-by="value"
        placeholder=""
      />
    </section>

    <section
      class="col pt-60"
      :class="{ 'validation-error': cart$.items.$error }"
    >
      <swiper-container
        class="slider"
        slides-per-view="auto"
        :initial-slide="greaterOrEqualMd ? 1 : 0"
      >
        <swiper-slide
          v-if="greaterOrEqualMd"
          class="h-auto w-[280px]"
        >
          <div class="flex flex-col gap-20 py-20 pr-20 text-14">
            <p>Based on your goal to <span class="font-bold">lose 51lbs</span>, along the information you provided, you may be eligible for our recommended treatment:</p>

            <p>Your prescription includes:</p>

            <ul class="flex flex-col gap-20">
              <li class="flex items-start gap-20">
                <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
                <span class="-mt-2 self-center">Personalized program:<br>Customized weight loss and medication unique to your weight loss goals</span>
              </li>
              <li class="flex items-start gap-20">
                <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
                <span class="-mt-2 self-center">Ongoing check-ins:<br>Concierge medical team to keep you on track</span>
              </li>
              <li class="flex items-start gap-20">
                <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
                <span class="-mt-2 self-center">Unlimited messaging:<br>We're here to support you every step of the way</span>
              </li>
              <li class="flex items-start gap-20">
                <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
                <span class="-mt-2 self-center">Free shipping:<br>Cold chain shipping Direct to your door</span>
              </li>
            </ul>
          </div>
        </swiper-slide>

        <swiper-slide
          v-for="item in mainProducts"
          :key="item.product.id"
          class="h-auto w-[250px] md:w-[360px]"
        >
          <ProductCard
            class="h-full"
            :item="item"
            :selected="selectedMainProductId === item.product.id"
            @select="selectMainProduct(item.product.id)"
          />
        </swiper-slide>
      </swiper-container>

      <p
        v-if="cart$.items.$error"
        class="relative mt-10 text-red"
      >
        {{ cart$.items.$errors[0]?.$message }}
      </p>

      <div
        v-if="!greaterOrEqualMd"
        class="mt-30 flex flex-col gap-20 text-14"
      >
        <p>Your prescription includes:</p>

        <ul class="flex flex-col gap-20">
          <li class="flex items-start gap-20">
            <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
            <span class="-mt-2 self-center">Personalized program:<br>Customized weight loss and medication unique to your weight loss goals</span>
          </li>
          <li class="flex items-start gap-20">
            <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
            <span class="-mt-2 self-center">Ongoing check-ins:<br>Concierge medical team to keep you on track</span>
          </li>
          <li class="flex items-start gap-20">
            <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
            <span class="-mt-2 self-center">Unlimited messaging:<br>We're here to support you every step of the way</span>
          </li>
          <li class="flex items-start gap-20">
            <IconCheck class="h-20 w-20 shrink-0 text-turquoise" />
            <span class="-mt-2 self-center">Free shipping:<br>Cold chain shipping Direct to your door</span>
          </li>
        </ul>
      </div>
    </section>

    <section
      v-if="isUsesChecklist"
      class="col mt-40 border-t border-white pt-40"
      :class="{ 'validation-error': variant$.quantity.$error }"
    >
      <header>
        <h2 class="text-26 font-medium">
          How many uses per month do you anticipate?
        </h2>
        <p class="mt-10 text-13 md:text-18 md:font-semibold">
          If you are prescribed medication, how often do you expect to use it for sexual activity?
        </p>
      </header>

      <div class="mt-30 grid gap-20 md:grid-cols-[338px,338px]">
        <div>
          <h3 class="text-18 font-bold text-turquoise">
            Most popular options:
          </h3>
          <ul class="mt-10 flex flex-col gap-10">
            <li
              v-for="uses in [10, 8, 6, 4]"
              :key="uses"
            >
              <BaseCheck
                v-model="variantForm.quantity"
                class="items-center"
                :value="uses"
                :size="27"
                styled
                type="radio"
              >
                <span class="text-20">{{ uses }}x per month</span>
                <span class="shrink-0 text-right text-14 text-turquoise">
                  {{ selectedMainProduct!.price }}
                </span>
              </BaseCheck>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="text-18 font-bold text-turquoise">
            Other options:
          </h3>
          <ul class="mt-10 flex flex-col gap-10">
            <li
              v-for="uses in [16, 14, 12]"
              :key="uses"
            >
              <BaseCheck
                v-model="variantForm.quantity"
                class="items-center"
                :value="uses"
                :size="27"
                styled
                type="radio"
              >
                <span class="text-20">{{ uses }}x per month</span>
                <span class="shrink-0 text-right text-14 text-turquoise">
                  {{ selectedMainProduct!.price }}
                </span>
              </BaseCheck>
            </li>
          </ul>
        </div>
      </div>

      <p
        v-if="variant$.quantity.$error"
        class="relative mt-10 text-red"
      >
        {{ variant$.quantity.$errors[0]?.$message }}
      </p>
    </section>

    <section
      v-if="isDosageChecklist"
      class="col mt-40 border-t border-white pt-40"
      :class="{ 'validation-error': variant$.dosage.$error }"
    >
      <header>
        <h2 class="text-26 font-medium">
          Choose a dosage strength:
        </h2>
      </header>

      <!-- <p class="mt-30 text-13 md:font-semibold">
        If you’re new to medication, we recommend you request the following dosage strength. Your medical provider will help you determine if this, or any other, dosage is the right one for you.
      </p> -->

      <ul class="mt-20 flex flex-col gap-10">
        <li
          v-for="value in selectedMainProduct!.product.dosages"
          :key="value"
        >
          <BaseCheck
            v-model="variantForm.dosage"
            class="items-center"
            :value="value"
            :size="27"
            styled
            type="radio"
          >
            <span class="text-20">{{ value / 100 }}{{ selectedMainProduct!.product.dosageUnit }}</span>
          </BaseCheck>
        </li>
      </ul>

      <p
        v-if="variant$.dosage.$error"
        class="relative mt-10 text-red"
      >
        {{ variant$.dosage.$errors[0]?.$message }}
      </p>
    </section>

    <section
      v-if="periodOptions.length"
      class="col mt-40 border-t border-white pt-40"
      :class="{ 'validation-error': variant$.period.$error }"
    >
      <header>
        <h2 class="text-26 font-medium">
          Select your billing frequency:
        </h2>
        <p class="mt-10 text-13 md:text-18 md:font-semibold">
          Multi-month plans shipped every 3 months.
        </p>
        <p class="mt-10 text-14 font-bold text-turquoise">
          Multi-month supplies are beginning at starting doses.
        </p>
      </header>

      <ul class="mt-30 flex flex-col gap-10">
        <li
          v-for="option in periodOptions"
          :key="option.period"
        >
          <BaseCheck
            v-model="variantForm.period"
            class="items-center"
            :value="option.period"
            :size="27"
            styled
            type="radio"
          >
            <span class="text-20">{{ option.label }}</span>
            <span class="flex shrink-0 flex-col gap-6 text-right text-14 text-deep-blue-50">
              <template v-if="option.membershipAmount">
                <span class="text-14">
                  Membership
                  <span class="text-turquoise">{{ formatUnitAmountCents(option.membershipAmount) }}/mo</span>
                </span>

                <span class="text-14">
                  + Medication
                  <span class="text-turquoise">{{ formatUnitAmountCents(option.amount) }}/mo</span>
                </span>
              </template>

              <span
                v-else
                class="text-14 text-turquoise"
              >
                {{ formatUnitAmountCents(option.amount) }}/mo
              </span>
            </span>
          </BaseCheck>
        </li>
      </ul>

      <p
        v-if="variant$.period.$error"
        class="relative mt-10 text-red"
      >
        {{ variant$.period.$errors[0]?.$message }}
      </p>
    </section>

    <section
      v-if="quizStore.quizType === QUIZ_TYPE.QUIZ_WEIGHT_LOSS && addons.length"
      class="col mt-40 border-t border-white pt-40"
    >
      <header>
        <h2 class="text-26 font-medium">
          Optional add-ons:
        </h2>
        <p class="mt-10 text-13 md:text-18 md:font-semibold">
          These products have been selected to make your treatment work better for you.
        </p>
      </header>

      <swiper-container
        class="slider mt-30"
        slides-per-view="auto"
      >
        <swiper-slide
          v-for="item in addons"
          :key="item.product.id"
          class="h-auto w-[250px] md:w-[360px]"
        >
          <ProductCard
            class="h-full"
            :item="item"
            :selected="selectedAddonIdList.includes(item.product.id)"
            @select="selectAddon(item.product.id)"
          />
        </swiper-slide>
      </swiper-container>
    </section>

    <template v-if="cartForm.items.length">
      <section class="col mt-40 border-t border-white pt-40">
        <header>
          <h2 class="text-26 font-medium">
            Have a coupon code?
          </h2>
        </header>

        <form
          class="mt-30"
          @submit.prevent="applyCoupon"
        >
          <h3 class="px-16 text-11 font-bold uppercase text-turquoise">
            Coupon Code
          </h3>

          <div
            v-if="cartForm.coupon"
            class="mt-10 flex items-center gap-10"
          >
            <p class="grow text-20 font-bold text-turquoise">
              {{ cartForm.coupon }}
            </p>
            <button
              class="flex h-[52px] w-[52px] shrink-0 items-center justify-center rounded-full bg-turquoise text-white hover:bg-deep-blue"
              type="button"
              data-testpl="quiz-cart-remove-coupon-btn"
              @click="removeCoupon"
            >
              <IconMinus />
            </button>
          </div>

          <div
            v-else
            class="mt-10 flex items-center gap-10"
          >
            <input
              v-model="couponInput"
              class="input grow"
              type="text"
            >
            <button
              class="flex h-[52px] w-[52px] shrink-0 items-center justify-center rounded-full text-white"
              :class="{
                'bg-turquoise hover:bg-deep-blue': couponInput,
                'bg-warm-grey': !couponInput
              }"
              type="submit"
              :loading="estimateLoading"
              :disabled="!couponInput || estimateLoading"
              data-testpl="quiz-cart-apply-coupon-btn"
            >
              <IconSpinner v-if="estimateLoading" />
              <IconPlus v-else />
            </button>
          </div>
        </form>
      </section>

      <section class="col pt-40">
        <div class="flex flex-col gap-10 rounded-[20px] bg-turquoise p-20 text-white">
          <!-- <ul class="flex flex-col gap-10 text-14">
            <li class="flex items-start justify-between gap-20">
              <p>Membership (x{{ variantForm.period }} months)</p>
              <p>{{ formatUnitAmountCents(membershipPrice) }}</p>
            </li>
            <li class="flex items-start justify-between gap-20">
              <p>Medication (x{{ variantForm.period }} months)</p>
              <p>{{ formatUnitAmountCents(medicationPrice) }}</p>
            </li>
            <li class="flex items-start justify-between gap-20">
              <p>Add-ons (x{{ variantForm.period }} months)</p>
              <p>{{ formatUnitAmountCents(addonPrice) }}</p>
            </li>
          </ul>

          <hr class="border-t border-white opacity-20"> -->

          <div class="flex items-start justify-between gap-20 text-24 font-light">
            <p>Total</p>
            <p v-if="cartForm.coupon">
              {{ formatUnitAmountCents(discountPrice) }}
              <span class="line-through">{{ formatUnitAmountCents(totalPrice) }}</span>
            </p>
            <p v-else>
              {{ formatUnitAmountCents(totalPrice) }}
            </p>
          </div>

          <hr class="border-t border-white opacity-20">

          <div class="flex items-start justify-between gap-20 text-24 font-light">
            <p>Due now</p>
            <p class="font-bold">
              {{ formatUnitAmountCents(membershipPrice / variantForm.period!) }}
            </p>
          </div>
        </div>
      </section>

      <section
        ref="cardSection"
        class="col mt-40 border-t border-white pt-40"
      >
        <header>
          <h2 class="text-26 font-medium">
            Save your card details:
          </h2>
          <p class="mt-10 text-13 md:text-18 md:font-semibold">
            If prescribed, you’ll be charged $897 every 3 months. You can cancel anytime prior to your next billing cycle.
          </p>
        </header>

        <form class="mt-30">
          <CardComponent
            ref="cardComponent"
            class="flex gap-10"
            :icon="false"
            v-bind="cardSettings"
            data-testpl="quiz-cart-card-component"
            @change="onCardChange"
          >
            <div class="grow">
              <h3
                class="px-16 text-11 font-bold uppercase text-turquoise"
                :class="{ '!text-red': cardErrors.number }"
              >
                Credit card number
              </h3>
              <div class="relative">
                <CardNumber
                  class="input mt-10 flex items-center !pl-50"
                  placeholder=" "
                />
                <IconCreditCard class="pointer-events-none absolute left-16 top-1/2 -translate-y-1/2" />
              </div>
            </div>
            <div class="w-[110px]">
              <h3
                class="px-16 text-11 font-bold uppercase text-turquoise"
                :class="{ '!text-red': cardErrors.expiry }"
              >
                MM/YY
              </h3>
              <CardExpiry
                class="input mt-10 flex items-center"
                placeholder=" "
              />
            </div>
            <div class="w-[60px]">
              <h3
                class="px-16 text-11 font-bold uppercase text-turquoise"
                :class="{ '!text-red': cardErrors.cvv }"
              >
                CVC
              </h3>
              <CardCvv
                class="input mt-10 flex items-center !px-10"
                placeholder=" "
              />
            </div>
          </CardComponent>

          <p
            v-if="cardErrorMessage"
            class="relative mt-10 text-red"
          >
            {{ cardErrorMessage }}
          </p>
        </form>
      </section>

      <section
        class="col mt-40 border-t border-white pt-40"
        :class="{ 'validation-error': billingAddress$.$error }"
      >
        <header>
          <h2 class="text-26 font-medium">
            Add your billing address:
          </h2>
        </header>

        <AddressForm
          class="mt-30"
          :address="cartForm.billingAddress"
          :validation="billingAddress$.billingAddress"
          :states="content.states"
          @update="(address) => cartForm.billingAddress = address"
        />
      </section>

      <section
        class="col mt-40 border-t border-white pt-40"
        :class="{ 'validation-error': shippingAddress$.$error }"
      >
        <header>
          <h2 class="text-26 font-medium">
            Add your shipping address:
          </h2>
        </header>

        <div class="mt-30 flex items-center gap-10">
          <BaseSwitch v-model="isShippingAddressSame" />
          <p>Ship to my billing address</p>
        </div>

        <AddressForm
          v-if="!isShippingAddressSame"
          class="mt-30"
          :address="cartForm.shippingAddress!"
          :validation="shippingAddress$.shippingAddress"
          :states="content.states"
          @update="(address) => cartForm.shippingAddress = address"
        />
      </section>

      <section
        v-if="quizStore.quizType === QUIZ_TYPE.QUIZ_WEIGHT_LOSS"
        class="col mt-40 border-t border-white pt-40"
        :class="{ 'validation-error': checkboxes$.$error }"
      >
        <header>
          <h2 class="text-15 font-medium uppercase text-turquoise">
            For your safety:
          </h2>
        </header>

        <div class="mt-10 text-12">
          <p>You should not take GLP-1s if you're planning on becoming pregnant. It can take up to 6 weeks for GLP-1s to be completely out of your body.</p>
          <p class="mt-10">
            If you're planning to conceive, stop GLP-1s at least 2 months prior to conception or actively trying to conceive a baby.
          </p>
        </div>

        <div class="mt-40">
          <BaseCheck
            v-model="checkboxesForm.agreement"
            class="gap-10"
          >
            <span class="text-14 font-semibold">
              I acknowledge that I have read and agree to the
              <a
                href="#"
                target="_blank"
              >
                JRNYS Self Injection Consent form
              </a>
            </span>
          </BaseCheck>

          <p
            v-if="checkboxes$.agreement.$error"
            class="relative mt-10 text-red"
          >
            {{ checkboxes$.agreement.$errors[0]?.$message }}
          </p>
        </div>
      </section>

      <section class="col flex items-center gap-20 pt-40">
        <BaseButton
          class="min-w-[95px] text-warm-grey hover:bg-turquoise hover:text-white sm:order-first"
          text="Back"
          size="sm"
          animated
          data-testpl="quiz-cart-back-btn"
          @back="emit('back')"
        >
          <template #icon-left>
            <ChevronLeft />
          </template>
        </BaseButton>

        <BaseButton
          class="min-w-[220px] bg-turquoise text-white hover:bg-deep-blue"
          text="Pay & continue"
          animated
          :loading="buyNowLoading"
          data-testpl="quiz-cart-pay-btn"
          @click="buyNow"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </section>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.col {
  @apply
    max-w-[540px]
    w-full
    mx-auto;
}

.slider {
  @apply
    w-full;

  &::part(container) {
    @apply
      overflow-visible;
  }

  swiper-slide:not(:last-child) {
    @apply
      mr-10
      md:mr-20;
  }
}

.input {
  &.focus {
    outline-color: theme('colors.turquoise');
  }

  &.invalid {
    outline-color: theme('colors.red');
  }
}
</style>
