import { CartItemPayload } from '@models/cart';
import { QUIZ_TYPE } from '@models/quiz';
import { useQuizStore } from '@stores/quiz';
import { required, helpers, requiredUnless, minLength } from '@vuelidate/validators';
import type { Ref } from 'vue';
import type { VariantForm } from '../types';

export const useValidators = ({
  isShippingAddressSame,
}: {
  isShippingAddressSame: Ref<boolean>
}) => {
  const quizStore = useQuizStore();

  const items = {
    minLength: {
      $validator: (items: CartItemPayload[]) => {
        return items.length > 0;
      },
      $message: () => 'Product is required',
    },
  };

  const period = {
    required: helpers.withMessage('Frequency is required', required),
  };

  const dosage = {
    required: {
      $validator(value: VariantForm['dosage']) {
        if (quizStore.quizType === QUIZ_TYPE.QUIZ_WEIGHT_LOSS) {
          return true;
        }
        return !!value;
      },
      $message: () => 'Dosage is required',
    },
  };

  const quantity = {
    required: {
      $validator(value: VariantForm['quantity']) {
        if (quizStore.quizType === QUIZ_TYPE.QUIZ_WEIGHT_LOSS) {
          return true;
        }
        return !!value;
      },
      $message: () => 'Quantity is required',
    },
  };

  const billingAddress = {
    firstName: { required: helpers.withMessage('Required', required) },
    lastName: { required: helpers.withMessage('Required', required) },
    phone: {
      required: helpers.withMessage('Required', required),
      minLength: minLength(12),
    },
    line1: { required: helpers.withMessage('Required', required) },
    city: { required: helpers.withMessage('Required', required) },
    zip: {
      required: helpers.withMessage('Required', required),
      minLength: minLength(5),
    },
    state_code: { required: helpers.withMessage('Required', required) },
  };

  const shippingAddress = {
    firstName: { required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)) },
    lastName: { required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)) },
    phone: {
      required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)),
      minLength: minLength(12),
    },
    line1: { required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)) },
    city: { required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)) },
    zip: {
      required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)),
      minLength: minLength(5),
    },
    state_code: { required: helpers.withMessage('Required', requiredUnless(isShippingAddressSame)) },
  };

  const agreement = {
    required: {
      $validator(value: boolean) {
        if (quizStore.quizType !== QUIZ_TYPE.QUIZ_WEIGHT_LOSS) {
          return true;
        }
        return !!value;
      },
      $message: () => 'Agreement is required',
    },
  };

  return {
    items,
    period,
    dosage,
    quantity,
    billingAddress,
    shippingAddress,
    agreement,
  };
};
