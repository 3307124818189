import { apiUser } from '@api/user';
import { useFetch } from '@helpers/use-fetch';
import { computed } from 'vue';

export const useProfile = () => {
  const getProfileApi = useFetch(apiUser.getProfile);
  const profileData = computed(() => getProfileApi.response.value?.data);
  const profileUser = computed(() => profileData.value?.user);

  const profileFirstName = computed(() => profileData.value?.user.firstName);
  const profileLastName = computed(() => profileData.value?.user.lastName);
  const profileBillingAddress = computed(() => profileData.value?.user.addresses.billingAddress);
  const profileShippingAddress = computed(() => profileData.value?.user.addresses.shippingAddress);

  getProfileApi.execute({});

  return {
    profileUser,
    profileBillingAddress,
    profileShippingAddress,
  };
};
