import { ref } from 'vue';
import { ChargebeeChangeEvent, ChargebeeFieldType, ChargebeeFieldError } from '../types';

export const useChargebee = () => {
  const cardSettings = {
    styles: {
      base: {
        fontSize: '20px',
        color: '#222',

        '::placeholder': {
          color: '#BEBFC6',
        },
      },
    },
    classes: {
      focus: 'focus',
      invalid: 'invalid',
    },
  };

  const cardErrors = ref<Partial<Record<ChargebeeFieldType, ChargebeeFieldError>>>({});
  const cardErrorMessage = ref<string>();

  const onCardChange = (status: ChargebeeChangeEvent) => {
    cardErrors.value = { ...cardErrors.value, [status.field]: status.error };
    const { message } = Object.values(cardErrors.value).filter((message) => !!message).pop() || {};
    cardErrorMessage.value = message;
  };

  return {
    cardSettings,
    onCardChange,
    cardErrors,
    cardErrorMessage,
  };
};
